import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatToolbarModule, MatIconModule, MatSidenavModule, MatListModule, MatButtonModule } from  '@angular/material';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { RfpComponent } from './rfp/rfp.component';
import { SettlementsComponent } from './settlements/settlements.component';

import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database/';
import { RegisterComponent } from './admin/register/register.component';
import { ForgotPasswordComponent } from './admin/forgot-password/forgot-password.component';
import { VerifyEmailComponent } from './admin/verify-email/verify-email.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OrderModule } from 'ngx-order-pipe';
import { QRDialog } from './rfp/rfp.component'

import { HttpClientModule } from '@angular/common/http';

import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

import { AuthService} from './auth/auth.service'

import { MatDialog} from '@angular/material/dialog'
import { OverlayModule } from '@angular/cdk/overlay';
import { MatDialogModule, MatDatepickerModule, MatNativeDateModule} from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

var config = {
  apiKey: "AIzaSyBO8x05saucFfgQdp0EuASIU6-K0TdBAgc",
  authDomain: "parfetts-1844b.firebaseapp.com",
  databaseURL: "https://parfetts-1844b-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "parfetts-1844b",
  storageBucket: "parfetts-1844b.appspot.com",
  messagingSenderId: "907278641620",
  appId: "1:907278641620:web:a7673373e30af0126ece41",
  measurementId: "G-3HBEH7SN92"
};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RfpComponent,
    SettlementsComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    QRDialog
  ],
  entryComponents: [
    QRDialog,
  ],
  imports: [
    BrowserModule,
    OrderModule,
    FormsModule,
    NgxQRCodeModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatSortModule,
    MatDatepickerModule,        // <----- import(must)
    MatNativeDateModule,        // <----- import for date formating(optional)
    AngularFireModule.initializeApp(config),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule
  ],
  providers: [AuthService, MatDialog],
  bootstrap: [AppComponent]
})
export class AppModule { }
