import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { Router } from  "@angular/router";
import { SessionService } from '../session.service';
import { FirebaseutilsService } from '../firebaseutils.service';
import { AngularFireDatabase } from '@angular/fire/database';

import {FormControl, FormGroup, Validators} from '@angular/forms';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { Observable } from 'rxjs';

export interface Settlement {
  timeStamp: number;
  accountNum: string;
  paymentRef: string;
  amountPay: string;
}

@Component({
  selector: 'app-settlements',
  templateUrl: './settlements.component.html',
  styleUrls: ['./settlements.component.css']
})



export class SettlementsComponent implements OnInit {

//items: Observable<any[]>;

public searchForm: FormGroup;
public departureDate = '';
public customerName = '';

allItems: object[] = [];

startDateStamp = -1;
endDateStamp = -1;
custNameFilter = "";


dataSource = new MatTableDataSource(this.allItems);
displayedColumns: string[] = ['timeCol', 'accountNum', 'paymentRef', 'customerName', 'amountPay'];


@ViewChild(MatSort, {static: false}) sort: MatSort;

constructor(
  public router: Router,
  private sessionService: SessionService,
  public afDb: AngularFireDatabase, // Inject Firebase auth service
  private firebaseutilsService: FirebaseutilsService,
) { }

  ngOnInit() {
    console.log ("settlements page");



    let uuid = this.sessionService.getSessionData("uuid");


    console.log ("uuid : " + uuid);

    if ((uuid == undefined) || (uuid == "")) {
      this.router.navigate(['dashboard']);
    } else {
      this.getSettlements(uuid);
    }

    this.searchFormInit();

    //this.items = this.firebaseutilsService.getSettlements (uuid);
    //console.log ("this.items : " + this.items);
    this.dataSource.filterPredicate = this.getFilterPredicate();

    this.dataSource.sortingDataAccessor = (item, property) => {

      console.log ("property : " + property);

       switch (property) {
         case 'timeCol': return  item["timeStamp"];
         default: return item[property];
    }};

  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  searchFormInit() {
    this.searchForm = new FormGroup({
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      customerName: new FormControl(''),
    });
  }

  applyFilter () {
    console.log ("applyFilter");

    const startDate = this.searchForm.get('startDate').value;
    const endDate = this.searchForm.get('endDate').value;
    const customerName = this.searchForm.get('customerName').value;

    console.log ("startDate : " + startDate);
    console.log ("endDate : " + endDate);
    console.log ("customerName : " + customerName);

    var startDateStr = "";
    var endDateStr = "";
    var customerNameStr = "";

    this.startDateStamp = -1;
    this.endDateStamp = -1;
    this.custNameFilter = "";

    if ((customerName != null) && (customerName != "")) {
      customerNameStr = customerName.toLowerCase();
      this.custNameFilter = customerName.toLowerCase();
    }


    if ((startDate != null) && (startDate != "")) {
      startDateStr = startDate.getTime();
      this.startDateStamp = startDate.getTime();
    }

    if ((endDate != null) && (endDate != "")) {
      endDateStr = endDate.getTime();
      this.endDateStamp = endDate.getTime();
    }

    const filterValue = startDateStr + '$' + endDateStr + '$' + customerNameStr;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  refresh(): void {
    this.dataSource.data = this.dataSource.data;
}

calculation() {
  let sum: number = 0;
  if (this.dataSource)
    for (let row of this.dataSource.data) {

      let timeStamp = Number (row["timeStamp"]);
      let customerName = row["customerName"];

      var countRow = true;
      var countRowStartDate = true;
      var countRowEndDate = true;


      if (this.custNameFilter != "") {

        if (customerName.toLowerCase().search(this.custNameFilter) != -1) {
          countRow = true;
        } else {
          countRow = false;
        }

      } else {
        //countRow = true;
      }

      if (this.startDateStamp != -1) {
        if (timeStamp > this.startDateStamp) {
          countRowStartDate = true;
        } else {
          countRowStartDate = false;
        }
      } else {
        //countRow = true;
      }

      if (this.endDateStamp != -1) {
        if (timeStamp < this.endDateStamp) {
          countRowEndDate = true;
        } else {
          countRowEndDate = false;
        }
      } else {
        //countRow = true;
      }

      //console.log ("countRow : " + countRow + ", countRowStartDate : " + countRowStartDate + ", countRowEndDate : " + countRowEndDate);

      //console.log ("amountPay : " + row["amountPay"]);

      if (countRow && countRowStartDate && countRowEndDate) {

        let amountPay = Number (row["amountPay"]);

        if (isNaN(amountPay) == false) {
          //console.log ("adding : " + Number (row["amountPay"]));
          sum = sum + Number (row["amountPay"]);
        }


      }

    }

  console.log ("sum : " + sum);

  return sum;
}

logOut () {
  console.log ("logOut");

  this.sessionService.clearSession ();
  this.router.navigate(['dashboard']);

}

  getFilterPredicate() {
    return (row: object, filters: string) => {
      const filterArray = filters.split('$');

      const startDateTimeStamp = filterArray[0];
      const endDateTimeStamp = filterArray[1];
      const custNameFilre = filterArray[2];

      const matchFilter = [];

      const colDateTimeStamp = row["timeStamp"];
      const colCustomerName = row["customerName"];


      var colDate = new Date(colDateTimeStamp);
      console.log ("colDate : " + colDate);
      console.log ("startDateTimeStamp : " + startDateTimeStamp);

      if (startDateTimeStamp != "") {
        var date = new Date(parseInt(startDateTimeStamp));
        const customDateFilter = colDate > date;

        console.log ("date : " + date);

        matchFilter.push(customDateFilter);
      }

      if (endDateTimeStamp != "") {
        var date = new Date(parseInt(endDateTimeStamp));
        const customDateFilter = colDate < date;

        console.log ("date : " + date);

        matchFilter.push(customDateFilter);
      }


      if (custNameFilre != "") {

        console.log ("custNameFilre : " + custNameFilre);

        const customDateFilter = (colCustomerName.toLowerCase().search(custNameFilre) != -1)

        matchFilter.push(customDateFilter);
      }








      return matchFilter.every(Boolean);
    }
  }

  getSettlements (uuid) {

    console.log ("uuid : " + uuid);

    this.afDb.list("payments/" + uuid)
      .valueChanges()
      .subscribe((datas) => {
        console.log("datas", JSON.stringify (datas))

        //this.allItems = any[];

        for(var data in datas) {

            console.log ("data : " + JSON.stringify (datas [data]));

            this.allItems.push (datas [data] as any);

/*
            let settData = datas [data] as any;

            for(var index in settData) {
              console.log ("index : " + JSON.stringify (settData[index]));

              var date = new Date(settData[index].timeStamp);

              settData[index]["date"] = date;

              this.allItems.push (settData[index]);

              //let settle = new Settlement ()

            }*/

            this.refresh ();
            //this.dataSource.data = this.allItems;

          //console.log ("data : " + items[index] [i]);

        }

        //this.items = allItems;

      },  (err)=> {
        console.log("probleme : ", err)
      }
    );

  }

  convertTimeStampToTime (dateSecs) {
    var d = new Date(dateSecs);

    let hourStr = d.getHours() + "";
    let minsStr = d.getMinutes() + "";
    let secsStr = d.getSeconds() + "";

    if (d.getHours() < 10) {
      hourStr = "0" + hourStr;
    }

    if (d.getMinutes() < 10) {
      minsStr = "0" + minsStr;
    }

    if (d.getSeconds() < 10) {
      secsStr = "0" + secsStr;
    }

    let outStr = hourStr + ':' + minsStr + ':' + secsStr;
    return outStr;
  }

  convertTimeStampToDate (dateSecs) {
    var d = new Date(dateSecs);

    let year = d.getFullYear() - 2000;

    let mnthStr = (d.getMonth() + 1) + "";
    let dayStr = (d.getDate() + 1) + "";

    if ((d.getMonth() + 1) < 10) {
      mnthStr = "0" + mnthStr;
    }

    if (d.getDate() < 10) {
      dayStr = "0" + dayStr;
    }

    let outStr = dayStr + '/' + mnthStr + '/' + year;
    return outStr;
  }

}
