import { Injectable, NgZone } from '@angular/core';

import { Router } from  "@angular/router";

import firebase from "firebase/app"
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

import { SessionService } from '../session.service';

//import { auth } from  'firebase/app';
import { AngularFireAuth } from  "@angular/fire/auth";

import { AngularFireDatabase } from '@angular/fire/database';

//type blah = firebase.Auth

import { User } from "../shared/services/user";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userData: any; // Save logged in user data

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private sessionService: SessionService,
    public afDb: AngularFireDatabase, // Inject Firebase auth service
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    })
  }

  // Sign in with email/password
  SignIn(email, password) {

    console.log ("email : " + email);

    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        this.ngZone.run(() => {
          console.log ("login success...!");
          this.router.navigate(['dashboard']);
        });
        this.SetUserData(result.user);
      }).catch((error) => {
        console.log ("error : " + error.message);
        window.alert(error.message)
      })
  }

// Auth logic to run auth providers
AuthLogin(provider) {
  return this.afAuth.signInWithPopup(provider)
  .then((result) => {
     this.ngZone.run(() => {
        this.router.navigate(['dashboard']);
      })
    this.SetUserData(result.user);
  }).catch((error) => {
    window.alert(error)
  })
}

  getUserDetails (uuid) {

    console.log ("checkForAdmin : " + uuid);

    this.afDb.object("users" + "/"+ uuid).valueChanges().subscribe(details => {
      console.log ("details : " + JSON.stringify(details));

      this.sessionService.setSessionData("email",  details ["notiEmail"]);
      this.sessionService.setSessionData("destSortCode",  details ["sortCode"]);
      this.sessionService.setSessionData("destAccountNumber",  details ["accountNumber"]);
      this.sessionService.setSessionData("destAccountName",  details ["accountName"]);

    })

  }


  SetUserData(user) {

    console.log ("SetUserData");

    this.getUserDetails (user.uid);

    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);

    console.log ("user.uid : " + user.uid);
    this.sessionService.setSessionData("uuid", user.uid);

    const userData: User = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified
    }
    return userRef.set(userData, {
      merge: true
    })
  }

}
