import { Component, OnInit } from '@angular/core';
import { AuthService } from  '../auth/auth.service';
import { FirebaseutilsService } from '../firebaseutils.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(
    private  authService:  AuthService,
    private firebaseutilsService: FirebaseutilsService,
  ) { }

  ngOnInit() {
  }

}
