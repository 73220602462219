import { Component, OnInit, Inject } from '@angular/core';

import { Router } from  "@angular/router";
import { SessionService } from '../session.service';
import { FirebaseutilsService } from '../firebaseutils.service';
import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms'
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';

import { AngularFireDatabase } from '@angular/fire/database';

import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-rfp',
  templateUrl: './rfp.component.html',
  styleUrls: ['./rfp.component.css']
})
export class RfpComponent implements OnInit {

  users: Observable<any[]>;
  depots: Observable<any[]>;
  customers: Observable<any[]>;
  customersFlat = [Observable];

  customersVeryFlat = Array ();

elementType = NgxQrcodeElementTypes.URL;
correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
value = 'https://www.techiediaries.com/';

  reactiveForm = new FormGroup({
    customerName: new FormControl(''),
    depotRef: new FormControl(''),
    accountNumber: new FormControl(''),
    invoiceRef: new FormControl(''),
    amount: new FormControl(''),
    destination: new FormControl(''),
    paymentType: new FormControl(''),
    branchCombo: new FormControl(''),

  })

  constructor(
    public afDb: AngularFireDatabase, // Inject Firebase auth service
    public router: Router,
    private sessionService: SessionService,
    private firebaseutilsService: FirebaseutilsService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    console.log ("rfp page");

    this.reactiveForm.controls['customerName'].setValue("Hops & Barley");

    let uuid = this.sessionService.getSessionData("uuid");

    console.log ("uuid : " + uuid);

    if ((uuid == undefined) || (uuid == "")) {
      this.router.navigate(['dashboard']);
    }

    this.users = this.firebaseutilsService.getUsers ();
    this.depots = this.firebaseutilsService.getDepots ();
    this.customers = this.firebaseutilsService.getCustomers ();

    this.getCustomersFlat();

    //console.log ("this.users : " + JSON.stringify(this.users | async));

    //let usersArray = this.users | async



    this.reactiveForm.get("accountNumber").valueChanges.subscribe(selectedValue => {
      this.reactiveForm.get("accountNumber").markAsDirty();
    })

    this.reactiveForm.get("amount").valueChanges.subscribe(selectedValue => {
      this.reactiveForm.get("amount").markAsDirty();
    })

    this.reactiveForm.get("invoiceRef").valueChanges.subscribe(selectedValue => {
      this.reactiveForm.get("invoiceRef").markAsDirty();
    })

  }

  getCustomersFlat () {

    console.log ("getCustomersFlat");


    var customerBanches = Array ();

    this.afDb.list("customers").snapshotChanges().pipe(take(1)).subscribe((markers: any) => {
    //this.afDb.list("customers").snapshotChanges().subscribe((markers: any) => {

      markers.forEach(singlemarker => {
        console.log ("singlemarker : " + JSON.stringify(singlemarker));



        this.customersFlat.push (singlemarker);


        let custData = singlemarker.payload;
        console.log ("branchData : " + JSON.stringify(custData));

        let custDataRemade = JSON.parse (JSON.stringify(custData));

        console.log ("customerName : " + custDataRemade["custBranch"]);

        var isSelected = false;

        if (custDataRemade["custBranch"] == "Anfield") {
          console.log ("sawSelected");
          isSelected = true;
        }

        let branchData = {
          "custName" : custDataRemade["custBranch"],
          "isSelect" : isSelected,
          "email" : custDataRemade["email"],
          "destAccountName" : custDataRemade["destAccountName"],
          "destAccountNum" : custDataRemade["destAccountNum"],
          "destSortCode" : custDataRemade["destSortCode"],
          "customerId" : custDataRemade["customerId"],
          "custBranch" : custDataRemade["custBranch"],
        }

        console.log ("branchData : " + JSON.stringify(branchData));

        this.customersVeryFlat.push (branchData);



        });

        // selects the correct customer
        this.reactiveForm.controls['customerName'].setValue("Anfield");

      console.log ("doneGettingMarkers : " + JSON.stringify(this.customersVeryFlat))
  });
  }


  logOut () {
    console.log ("logOut");

    this.sessionService.clearSession ();
    this.router.navigate(['dashboard']);

  }

  QRButtonPressed (form: NgForm, customerName, accountNum, paymentRef, amountPay, destination, paymentType) {
    console.log ("QRButtonPressed");

    if ((customerName == "") || (accountNum == "") || (paymentRef == "") || (amountPay == "")) {
      window.alert("Please complete form...!");
      return
    }


    //this.paymentButtonPressed (form, customerName, accountNum, paymentRef, amountPay, destination, paymentType);

    let qrCode = customerName + "," + accountNum + "," + paymentRef + "," + amountPay

    let dataUse = {
      qrCode : qrCode,
    }

    const dialogRef = this.dialog.open(QRDialog, {
      width: '200px',
      data: dataUse
    });

    dialogRef.afterClosed().subscribe(result => {

       console.log ("sending notification");
    });
  }

  paymentButtonPressed (form: NgForm, accountNum, paymentRef, amountPay, destination, paymentType) {

    console.log ("paymentButtonPressed");

    let customerName = this.reactiveForm.value ["customerName"];


    console.log ("customerName : " + customerName);
    console.log ("accountNum : " + accountNum);
    console.log ("paymentRef : " + paymentRef);
    console.log ("amountPay : " + amountPay);
    console.log ("destination : " + destination);

    console.log ("paymentType : " + paymentType);

    console.log ("this.customersFlat : " + this.customersFlat.length);


/*
    for (let idx in this.customersFlat) {
      let customer = this.customersFlat [idx];

      let payload = customer.payload;

      let payloadStr = JSON.stringify (payload);
      let obj = JSON.parse (payloadStr);

      let custName = obj.custName;

      console.log ("payload : " + JSON.stringify(payload));
      console.log ("payload : " + payload);
      console.log ("obj : " + JSON.stringify(obj));
      console.log ("obj.id : " + obj.customerId);
      console.log ("custName : " + obj.custName);
    }
*/

    //this.customers.map(obj => Object.values(obj))

    var errorMsg = "";

    if ((customerName == undefined) || (customerName == "")) {
      errorMsg = errorMsg + "• Customer Name\n"
    }

    /*
    if ((depotRef == undefined) || (depotRef == "")) {
      errorMsg = errorMsg + "• Depot Reference\n"
    }*/

    if ((accountNum == undefined) || (accountNum == "")) {
      errorMsg = errorMsg + "• Account Number\n"
    }

    if ((paymentRef == undefined) || (paymentRef == "")) {
      errorMsg = errorMsg + "• Payment Reference\n"
    }

    if ((amountPay == undefined) || (amountPay == "")) {
      errorMsg = errorMsg + "• Amount Pay\n"
    }

    if (errorMsg != "") {
      window.alert("Please enter the following:\n\n" + errorMsg);
      return;
    } else {
      form.resetForm();
      this.reactiveForm.reset();
      this.reactiveForm.controls.amount.setValue('');
    }

    let uuid = this.sessionService.getSessionData("uuid");



    let paymentRefUse = "EP" + accountNum + " " + paymentRef;

    if (paymentType != "") {
      paymentRefUse = paymentRefUse + " " + paymentType;
    }

    //console.log ("this.customers : " + JSON.stringify(this.customers));

    this.firebaseutilsService.sendPaymentRequest (form, uuid, customerName, "depotRef", accountNum, paymentRefUse, amountPay, destination, this.customersVeryFlat);

  }

}

export interface DialogData {
  qrCode: string;
}

@Component({
  selector: 'qrcode-dialog',
  templateUrl: 'qrcode-dialog.html',
})
export class QRDialog {

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = 'https://www.techiediaries.com/';

  constructor(
    public dialogRef: MatDialogRef<QRDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      this.value = data.qrCode;


    }


  onNoClick(): void {
    this.dialogRef.close();
  }

}

