import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private sessionVariables: Map<string, string> = new Map<string, string>();

  constructor() { }

  public setSessionData(key: string, value: string): void {
    this.sessionVariables.set(key, value);
  }

  public getSessionData(key: string): string {
    if (key !== undefined && key !== null) {
      return this.sessionVariables.get(key);
    }

    return undefined;
  }

  public clearSessionData(key: string): void {
    if (key !== undefined && key !== null) {
      this.sessionVariables.delete(key);
    }
  }

  public clearSession(): void {
    this.sessionVariables.clear();
  }
}
