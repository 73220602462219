import { Injectable } from '@angular/core';

import firebase from "firebase/app"
import { AngularFireDatabase } from '@angular/fire/database';

import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { SessionService } from './session.service';

import { NgForm, FormGroup, FormControl, Validators } from '@angular/forms'

import { v1 as uuidv1 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class FirebaseutilsService {

  constructor(
    public afDb: AngularFireDatabase, // Inject Firebase auth service
    private sessionService: SessionService,
    private http: HttpClient
  ) { }


  getUsers () {

    console.log ("getUsers");



    return this.afDb.list("users").valueChanges();
  }

  getDepots () {

    console.log ("getDepots");

    return this.afDb.list("depots").valueChanges();
  }

  getCustomers () {

    console.log ("getCustomers");

    return this.afDb.list("customers").valueChanges();
  }

  getCustomersFlat () {

    console.log ("getCustomersFlat");

    return this.afDb.list("customers").snapshotChanges().subscribe((markers: any) => {
      console.log ("getCustomersFlat");
  });
  }


  // not used
  getSettlements (uuid) {
    console.log ("getPaymentsFor : " + uuidv1);

    this.afDb.list("payments")
      .valueChanges()
      .subscribe((datas) => {
        console.log("datas", JSON.stringify (datas))

        var allItems = [];

        for(var data in datas) {

            console.log ("data : " + JSON.stringify (datas [data]));

            let settData = datas [data] as any;

            for(var index in settData) {
              console.log ("index : " + JSON.stringify (settData[index]));

              allItems.push (settData[index]);
            }

          //console.log ("data : " + items[index] [i]);

        }

        return allItems;

      },  (err)=> {
        console.log("probleme : ", err)
      }
    );

  }

  sendPaymentRequest (form: NgForm, uuid, customerName, depotRef, accountNum, paymentRef, amountPay, destinationId, customers) {

    console.log ("sendPaymentRequest");

    let paymentId = uuidv1();

    console.log ("uuid : " + uuid);
    console.log ("customerName : " + customerName);
    console.log ("depotRef : " + depotRef);
    console.log ("accountNum : " + accountNum);
    console.log ("paymentRef : " + paymentRef);
    console.log ("amountPay : " + amountPay);

    console.log ("destinationId : " + destinationId);

    var errorMsg = "";

    if ((customerName == undefined) || (customerName == "")) {
      errorMsg = errorMsg + "• Customer Name\n"
    }

    /*
    if ((depotRef == undefined) || (depotRef == "")) {
      errorMsg = errorMsg + "• Depot Reference\n"
    }*/

    if ((accountNum == undefined) || (accountNum == "")) {
      errorMsg = errorMsg + "• Account Number\n"
    }

    if ((paymentRef == undefined) || (paymentRef == "")) {
      errorMsg = errorMsg + "• Payment Reference\n"
    }

    if ((amountPay == undefined) || (amountPay == "")) {
      errorMsg = errorMsg + "• Amount Pay\n"
    }


    var destEmail = this.sessionService.getSessionData("email");
    console.log ("destEmail : " + destEmail);

    var nameBits = customerName.split (" (");

      let lookingIn = customerName;
      let depotName = customerName;
      let depotId = customerName;

      console.log ("lookingIn : " + lookingIn);

      var custDataFound = {};

      for (let idx in customers) {

        let customer = customers [idx];

        //let payload = customer.payload;
        //let payloadStr = JSON.stringify (payload);
        let obj = customer;

        let custName = obj.custName;
        let custEmail = obj.email;

        console.log ("custName : " + obj.custName);


        if (lookingIn.includes (custName)) {
          console.log ("found customer! : " + custEmail);
          destEmail = custEmail;
          custDataFound = customer;
        }


      console.log ("destEmail : " + destEmail);

    }


    console.log ("custDataFound : " + JSON.stringify (custDataFound));


    let destSortCode = this.sessionService.getSessionData("destSortCode");
    let destAccountNumber = this.sessionService.getSessionData("destAccountNumber");
    let destAccountName = this.sessionService.getSessionData("destAccountName");



    if (destSortCode == undefined) {
      destSortCode = custDataFound ["destSortCode"];
      destAccountNumber = custDataFound ["destAccountNum"];
      destAccountName = custDataFound ["destAccountName"];
    }


    if (errorMsg != "") {
      window.alert("Please enter the following:\n\n" + errorMsg);
      //return;
    } else {
      let timeStamp = new Date().getTime();

      console.log ("paymentId : " + paymentId);
      console.log ("customerName : " + customerName);
      //console.log ("depotName : " + depotName);
      console.log ("accountNum : " + accountNum);
      console.log ("paymentRef : " + paymentRef);
      console.log ("amountPay : " + amountPay);
      console.log ("timeStamp : " + timeStamp);
      console.log ("destEmail : " + destEmail);
      console.log ("destSortCode : " + destSortCode);
      console.log ("destAccountNumber : " + destAccountNumber);
      console.log ("destAccountName : " + destAccountName);

      let destinationBits = destinationId.split(" ");



      let toUuid = destinationBits[4];
      console.log ("toUuid : " + toUuid);


      if (destSortCode == undefined) {
        destSortCode = ""
      }

      if (destAccountName == undefined) {
        destAccountName = ""
      }

      if (destAccountName == undefined) {
        destAccountName = ""
      }

      const tutRef = this.afDb.object("payments/" + toUuid + "/" + paymentId);
      tutRef.set({
        customerName: customerName,
        depotRef: depotName,
        accountNum: accountNum,
        paymentRef: paymentRef,
        amountPay: amountPay,
        timeStamp: timeStamp,
        destEmail: destEmail,
        destSortCode: destSortCode,
        destAccountNumber: destAccountNumber,
        destAccountName: destAccountName,
        type: "notification"
      });



      //form.resetForm();

      this.sendPushNoti (paymentId, destinationId, depotId);

      window.alert("Payment Sent");
    }



    // store in firebase
  }


  sendPushNoti (paymentId, destinationId, depotId) {
    console.log ("sendPushNoti...!");

//    let paymentId = "301dd250-955d-11eb-bdf6-db9fede07b36";

    console.log ("paymentId : " + paymentId);


    let url = "https://fcm.googleapis.com/fcm/send";

    let serverKey = "key= AAAA0z4FadQ:APA91bF2VniYNohuGsjWxb_I-QaFlO528GR1M88ea5iExu0Ie2SUovj7eTWoZVHO1hkdTpdV6665F2MlTYcoeOQFa5wz1Wn9fSgomyhZwQbmng7mjDNGloty0Ro7gTs5c0WSh160pvM2";

//    let toId = "dBdWWm0hSg2tpjcEd7IGGK:APA91bH_LgTFGdRsIrN0p3jh96A0H3-paRR6wKUO7gSX8mL_iZDSpWVWzMnaddASTgGj64xe_igwb23muQwtv3L-mRR_JO3pYyjxS6dDdsZ6uhKrjsm5Ooybm9Vq-Sj2EJqn9Ue8ah1U";

    let destinationBits = destinationId.split(" ");

    let uuid = this.sessionService.getSessionData("uuid");

    let toId = destinationBits[0];
    let toUuid = destinationBits[4];

    console.log ("toId : " + toId);
    console.log ("toUuid : " + toUuid);

    let timeStamp = new Date().getTime();

    let noti = {
      "title" : "Payment Request Received",
      "body" : "Please open the EchoPay App.",
      "badge" : 1,
      "mutable_content" : "true",
      "priority":"high"
    };

    let msgData = {
      "message" : "my msg",
      "paymentId" : paymentId,
      "depotId" : depotId,
      "uuid" : toUuid,
      "color" : "black" + timeStamp,
      "priority":"high"
    }

    let msgPayload = {
      "to" : toId,
      "topic":"subscriber-updates",
      "data" : msgData,
      "notification" : noti,
      "priority": 10,
      "android" : {
        "priority":"high"
      },
      "webpush": {
        "headers": {
          "Urgency": "high"
        }
      }
    }


    let headers = {
      "Content-Type" : "application/json",
      "Authorization" : serverKey,
    }


    console.log ("headers : " + JSON.stringify(headers));
    console.log ("msgPayload : " + JSON.stringify(msgPayload));


    this.http.post (url, msgPayload, {'headers':headers}).subscribe(response => {
      console.log ("response : " + JSON.stringify(response));
    })
    //

    //console.log ("");


  }

  sendPushNotiTest () {
    console.log ("sendPushNoti...!");

//    let paymentId = "301dd250-955d-11eb-bdf6-db9fede07b36";




    let url = "https://fcm.googleapis.com/fcm/send";

    let serverKey = "key= AAAA0z4FadQ:APA91bF2VniYNohuGsjWxb_I-QaFlO528GR1M88ea5iExu0Ie2SUovj7eTWoZVHO1hkdTpdV6665F2MlTYcoeOQFa5wz1Wn9fSgomyhZwQbmng7mjDNGloty0Ro7gTs5c0WSh160pvM2";

    let toId = "eCtMPyONQfWXKXqRkw9X1c:APA91bGvs_UBBS-T54Bex65rtZmh_kegZ4-Vs2RFzBKIGpjlwzcgaeC_LBlqFV7XsYB9HB1DMuj0gQR-2ZPOPgRX4pWhj5G0Fx1TeEa4IeIp7K2b5inQk9onsw6rsswSLB7aVNT1sIxC";

    //let destinationBits = destinationId.split(" ");

    let uuid = this.sessionService.getSessionData("uuid");

    //let toId = destinationBits[0];
    let toUuid = uuid;

    console.log ("toId : " + toId);
    console.log ("toUuid : " + toUuid);

    let timeStamp = new Date().getTime();

    let noti = {
      "title" : "Payment Request Received",
      "body" : "Please open the EchoPay App.",
      "badge" : 1,
      "mutable_content" : "true",
    };

    let msgData = {
      "message" : "my msg",
      "paymentId" : "paymentId",
      "uuid" : toUuid,
      "color" : "black" + timeStamp
    }

    let msgPayload = {
      "to" : toId,
      "data" : msgData,
      "notification" : noti
    }


    let headers = {
      "Content-Type" : "application/json",
      "Authorization" : serverKey,
    }


    console.log ("headers : " + JSON.stringify(headers));
    console.log ("msgPayload : " + JSON.stringify(msgPayload));


    this.http.post (url, msgPayload, {'headers':headers}).subscribe(response => {
      console.log ("response : " + JSON.stringify(response));
    })
    //

    //console.log ("");


  }

}
